<template>
  <div className="animated">
    <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>Data Table Report Transaction ICH
      </b-card-header>
      <b-card-body>
        <report></report>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import report from "./Table";

export default {
  name: "ReportTransaction",
  components: {
    report
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
